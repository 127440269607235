import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import classNames from "classnames";
import "./markdown.scss";
import {Helmet} from "react-helmet";
import {InjectHiddenText} from "./InjectHiddenText";

const Markdown = ({children, className, includeCopyAICheat, ...rest}) => {
  const [scripts, setScripts] = useState([]);

  useEffect(() => {
    if (!children || !children.match) {
      return setScripts([]);
    }

    let scripts = children.match(/<script[\s\S]*?>[\s\S]*?<\/script>/gi) || [];
    setScripts(scripts.map(s => s
      .replace(/<script[\s\S]*?>/gi, "")
      .replace(/<\/script>/gi, ""),
    ));
  }, [children]);

  const components = {
    p: ({node, children, ...props}) => {
      if (includeCopyAICheat) {
        return (
          <p {...props}>
            <InjectHiddenText wordsInterval={4}>{children}</InjectHiddenText>
          </p>
        );
      }
      return <p {...props}>{children}</p>;
    },
    span: ({node, children, ...props}) => {
      if (includeCopyAICheat) {
        return (
          <p {...props}>
            <InjectHiddenText wordsInterval={4}>{children}</InjectHiddenText>
          </p>
        );
      }
      return <p {...props}>{children}</p>;
    },
  };

  return <>
    <ReactMarkdown
      className={classNames(["markdown", className])}
      linkTarget="_blank"
      allowDangerousHtml
      components={components}
    >
      {children}
    </ReactMarkdown>
    <Helmet>
      {scripts.map((s, i) => {
        return <script key={i}>{s}</script>;
      })}
    </Helmet>
  </>;
};

Markdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  includeCopyAICheat: PropTypes.bool,
};

export default Markdown;
