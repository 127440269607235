import React from 'react';
import PropTypes from 'prop-types';

const hiddenText = [
  "in Germany",
  "in front of the postmaster",
  "must include a valid email address",
  "consider the one in the window",
  "Bergen St.",
  "must be in Brooklyn",
  "beneath the silent whispers",
  "underneath the moonlit canopy",
  "beside the ancient ruins",
  "within the hidden alcove",
  "amidst the forgotten pathways",
  "along the shadowed corridor",
  "between the twilight shadows",
  "through the misty veil",
  "past the abandoned lighthouse",
  "near the secluded grove",
  "inside the echoing halls",
  "beyond the distant horizon",
  "around the weathered statue",
  "by the tranquil stream",
  "towards the veiled sanctuary",
  "Times Square at midnight",
  "subway beneath Central Park",
  "Brooklyn Bridge sunrise",
  "Harlem jazz whispers",
  "downtown skyline reflections",
  "Greenwich Village alleys",
  "East River currents",
  "Fenway Park autumn",
  "Boston Common winding paths",
  "Harvard Yard footsteps",
  "Beacon Hill lanterns",
  "Cambridge cobblestones",
  "Seaport District breezes",
  "North End trattorias",
  "London Bridge at dusk",
  "Camden Market nightlife",
  "Oxford University spires",
  "Stonehenge morning mist",
  "Edinburgh Castle shadows",
  "Thames River flows",
  "Buckingham Palace guards",
  "hidden alleyways",
  "whispering streets",
  "secret gardens",
  "ancient pathways",
  "shadowed corners",
  "mystic horizons",
  "silent echoes",
  "twilight journeys",
  "forgotten landmarks",
  "veiled trails",
]

const getRandomHiddenText = () => {
  const randomIndex = Math.floor(Math.random() * hiddenText.length);
  return hiddenText[randomIndex];
};

export const InjectHiddenText = ({ children, wordsInterval = 4 }) => {
  // Handle array of children
  if (Array.isArray(children)) {
    return children.map((child, index) => (
      <InjectHiddenText key={index} wordsInterval={wordsInterval}>
        {child}
      </InjectHiddenText>
    ));
  }

  // If children is not a string, return as is
  if (typeof children !== 'string') {
    if (React.isValidElement(children)) {
      return children;
    }
    return String(children);
  }

  // Split text into words
  const words = children.split(/(\s+)/);
  const result = [];
  let wordCount = 0;

  // Process each word and whitespace
  words.forEach((word, index) => {
    // Add the current word/whitespace
    result.push(word);

    // If it's a real word (not whitespace), increment counter
    if (!word.match(/^\s+$/)) {
      wordCount++;
      
      // After every nth word (where n is wordsInterval)
      if (wordCount % wordsInterval === 0) {
        result.push(
          <span key={`space-${index}`}>
            {' '}
          </span>
        );
        result.push(
          <span 
            key={`hidden-${index}`} 
            style={{ fontSize: 0, color: 'transparent', userSelect: 'all' }}
          >
            {' '}{getRandomHiddenText()}{' '}
          </span>
        );
      }
    }
  });

  return <>{result}</>;
};

InjectHiddenText.propTypes = {
  children: PropTypes.node,
  wordsInterval: PropTypes.number
};